/** Apply effect only for mouse */
@media only screen and (min-width: 450px) {
    .education-item-container-light:hover {
        background-color: #07427c;
    }

    .education-item-container-dark:hover {
        background-color: #07427c !important;
    }
}

.educationItemIcon:hover {
    background-color: #dee2e6;
    align-items: center;
    justify-content: center;
}

.educationPeriodItem {
    overflow: hidden;
    transition: transform 0.3s linear;
    height: auto;
    transform: scaleY(0);
    transform-origin: top;
}

.educationPeriodItem-collapsed {
    transform: scaleY(1);
}

.education-item-container-light {
    background-color: #edf2fb;
}

.education-item-container-dark {
    background-color: #073c6f !important;
}
