/** Apply effect only for mouse */
@media only screen and (min-width: 450px) {
    .tab:hover p {
        background-color: #9bb1ffbf !important;
    }

    .mode-dark:hover {
        background-color: #00186e !important;
    }

    .mode-light:hover {
        background-color: #abc4ff !important;
    }
}

.tab-item {
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -webkit-animation-duration: 25s;
    animation-duration: 25s;
}

.selected-light {
    background-color: #abc4ff !important;
}

.selected-dark {
    background-color: #000814 !important;
}

.selected-tab-light {
    background-color: rgb(204, 219, 253);
}
