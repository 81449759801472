.music-streaming-slide-dark {
    background-color: rgba(48, 99, 142, 0.75) !important;
}

.music-streaming-slide-dark {
    background-color: rgb(237, 242, 251) !important;
}

.music-streaming-swiper-container {
    min-height: 250px;
    /* width: 100%; */
}

.swiper-slide {
    /* height: 200px; */
    text-align: center;
    /* font-size: 18px; */
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-pagination-bullet {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    color: #343a40;
    opacity: 1;
    /* background: rgba(0, 0, 0, 0.2); */
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.swiper-pagination-bullet-active {
    color: #ffffff;
}

/** Apply effect only for mouse */
@media only screen and (min-width: 450px) {
    .swiper-pagination-bullet:hover {
        background-color: #b66ee8bf !important;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0px !important;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes modalFadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
