.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
/**
* Global style(s)
*/

span,
p,
img {
    user-select: none;
    -webkit-touch-callout: none;
}

div {
    -webkit-tap-highlight-color: transparent;
}

.animate {
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -webkit-animation-duration: 25s;
    animation-duration: 25s;
}

/**
*
* Light themes 
*
 **/

/** Apply effect only for mouse */
@media only screen and (min-width: 450px) {
    .link-light:hover {
        background-color: #e2eafc;
    }
    /* [class*="-tab"]:hover {
        background-color: red !important;
    } */
}

.active-tab-light {
    background-color: rgba(226, 234, 252, 0.75);
}

div [class$="-container-light"] {
    background-color: rgb(248, 249, 250);
}

/** Bio-items **/

[class$="-key-light"] {
    color: #212529;
}

[class$="-value-light"] {
    color: #6c757d;
}

/**
*
* Dark themes 
*
 **/

/** Apply effect only for mouse */
@media only screen and (min-width: 450px) {
    .link-dark:hover {
        background-color: #0d47a1;
    }
    /* [class*="-tab"]:hover {
        background-color: blue !important;
    } */
}

.active-tab-dark {
    background-color: transparent;
    border: 2px solid #397299 !important;
}

.selected-tab-dark {
    background-color: #00377e !important;
}

div [class$="-container-dark"] {
    background-color: rgb(33, 37, 41);
}

[class$="-section-header-dark"] {
    color: #f8f9fa;
}

[class$="-key-dark"] {
    color: #f8f9fa;
}

[class$="-value-dark"] {
    color: #adb5bd;
}

/** Tab themes **/

.selected-section {
    background-color: rgba(254, 228, 64, 0.5);
}
